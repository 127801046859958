
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import PageRequest from "../../../store/entities/page-request";
import PeopleFind from "../../../components/People/people-find.vue";
import PagedScreen from "../../people/tableBase.vue";
import PeoplePage from "../../people/people-page.vue";
import editienda from "./edit-tienda.vue";

class PageTiendaRequest extends PageRequest {
  
}
@Component({
  components: {
    PeopleFind,
    PeoplePage,
    editienda
  },
})
export default class Tienda extends PagedScreen {
  
  beforeMount() {
    this.context = "tienda";
    this.pagerequest = new PageTiendaRequest();
    
  }
 
  editRow(item, index, button) {
    this.$store.commit("tienda/edit", item);
    this.edit();
  }

  async deleteRow(item, index, button) {
    await this.$store.dispatch({
      type: "tienda/delete",
      data: item,
    });
    await this.getpage();
  }

  columns = [
    {
      title: this.L("Codigo Tienda"),
      dataIndex: "codigoCliente",
    },
    {
      title: this.L("Direccion"),
      dataIndex: "direccion",
    },
    {
      title: this.L("Email"),
      dataIndex: "email",
      
    },
    {
      scopedSlots: { customRender: "editBtn" },
      width: 10,
    },
    {
      scopedSlots: { customRender: "removeBtn" },
      width: 10,
    },
  ];

  searchDefinition: {}[] = [
    {
      fieldName: "CodigoCliente",
      fieldType: "text",
      fieldBind: "CodigoCliente",
      value: "",
    },
    {
      fieldName: "Direccion",
      fieldType: "text",
      fieldBind: "Direccion",
      value: "",
    },
     {
      fieldName: "Email",
      fieldType: "text",
      fieldBind: "Email",
      value: "",
    }
  ];
}
