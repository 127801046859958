
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Modal from '../../../components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import BaseSwitch from '@/components/Inputs/BaseSwitch.vue'
import Tienda from '../../../store/entities/OZONE/tienda'
import Provincia from '../../../store/entities/OZONE/provincia'
import Municipio from '../../../store/entities/OZONE/municipio'
import Cliente from '../../../store/entities/OZONE/cliente'
import selectProvincia  from '../provincia/selectProvincia.vue'
import selectMunicipio  from '../municipio/selectMunicipio.vue'
import selectCliente  from '../cliente/selectCliente.vue'
@Component({
  components: { Modal, BaseButton, BaseCheckbox, BaseSwitch, selectProvincia, selectMunicipio, selectCliente }
})
export default class EditMetodoEnvio extends AbpBase {
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: Boolean, default: false }) editing: boolean
  @Prop({ type: String }) id: string
  tienda: Tienda = new Tienda()
  provincia: Provincia = null
  municipio: Municipio = null
  cliente: Cliente = null
  title: String = ''
  createModalShow: boolean = false
  editModalShow: boolean = false
  disabled: boolean = false

  created() {}
  async save() {
    
    if (this.checkFormValidity()) {
      if (this.editing) {
        this.tienda.clienteId = this.cliente.id
        this.tienda.municipioId = this.municipio.id
        this.tienda.provinciaId = this.provincia.id
        await this.$store.dispatch({
          type: 'tienda/update',
          data: this.tienda
        })
      } 
      else {
        this.tienda.clienteId = this.cliente.id
        this.tienda.municipioId = this.municipio.id
        this.tienda.provinciaId = this.provincia.id
        await this.$store.dispatch({
          type: 'tienda/create',
          data: this.tienda
        })
      }

      this.$emit('save-success')
      this.$emit('input', false)
      this.resetFields()
    }
  }
  close() {
    this.$emit('input', false)
    this.resetFields()
  }

  async shown() {
    if (this.editing) {
      this.tienda = Util.extend(true, {}, this.$store.state.tienda.editTienda)
      await this.getProvinciasDropDown()
      await this.getMunicipiosDropdown()  
      this.title = 'Editar Tienda' 
    }
     else {
      this.title = 'Añadir Tienda'
    }
  }
  async getProvinciasDropDown() {
    await this.$store.dispatch({
      type: 'provincia/GetProvinciByID',
      data: this.tienda
    })
    
    this.provincia = this.$store.state.provincia.editProvincia
    this.cliente = this.$store.state.cliente.list.find(x =>(x.id ==  this.$store.state.tienda.editTienda.clienteId))
  }

  async getMunicipiosDropdown(){
    await this.$store.dispatch({
      type: 'municipio/getMunicipioById',
      data: this.tienda
    })

    this.municipio = this.$store.state.municipio.editMunicipio;
  }

  checkFormValidity() {
    const valid = (this.$refs.tiendaForm as any).checkValidity()
    return valid
  }
  resetFields() {
    this.tienda = new Tienda()
    this.municipio = null
    this.cliente = null
    this.provincia = null
    this.$store.commit("municipio/cleanCampos");
  }
  setDisabled() {
    this.disabled = !abp.auth.isAnyGranted('OZONE.CuentaEmisora.Edit', 'OZONE.CuentaEmisora.Create', 'OZONE.CuentaEmisora.Delete')
  }

  @Watch("provincia")
  async provinciaHasChanged(newValue){
    if(newValue){
      this.municipio = null;
      await this.$store.dispatch({
        type: "municipio/getMunicipio",
        data: { ProvinciaId: newValue.id},
      });
    }
  }

  TiendaRule = {
    codigoCliente: {
      required: { required: true },
      trigger: 'blur'
    },
    tipo: {      
    },
    direccion: {    
      required: { required: true },
      trigger: 'blur'  
    },
    cP: {      
      required: { required: true },
      trigger: 'blur'  
    },
    telefono: {
      required: { required: true },
      trigger: 'blur'        
    },
    idioma: {
      required: { required: true },
      trigger: 'blur'              
    },
    estado: {      
    },
    maestro: {
      required: { required: true },
      trigger: 'blur'        
    },
    email: {
      required: { required: true, email: true },
      trigger: 'blur'        
    },
    latitud: {      
    },
    longuitud: {      
    },   
    nombre: {
      required: { required: true },
      trigger: 'blur'        
    },
    nif: {
      required: { required: true },
      trigger: 'blur'        
    },
    razonSocial: {
      required: { required: true },
      trigger: 'blur'        
    },
    contacto:{
      required: { required: true },
      trigger: 'blur'  
    },
    provincia:{
      required: { filtrableDropdownRequired: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('provincia')),
      placeholder: this.L('provincia'),
      trigger: 'blur'
    },
    municipio: {
      required: { filtrableDropdownRequired: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('municipio')),
      placeholder: this.L('municipio'),
      trigger: 'blur'
    },
    cliente:{
      required: { filtrableDropdownRequired: true },
      trigger: 'blur'
    }
  }
}
