
import AbpBase from "../../../lib/abpbase";
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";

import PageRequest from "../../../store/entities/page-request";
import Municipio from "../../../store/entities/OZONE/municipio";

class PageMunicipioRequest extends PageRequest {
  nombre: string;
}
@Component
export default class selectMunicipio extends AbpBase {
  @Prop({ type: Object, default: null }) value: Municipio;
  @Prop({ type: Boolean, default: true }) showPagination: Boolean;
  @Prop({ type: Boolean, default: false }) required: Boolean;
  @Prop({ type: Boolean, default: false }) disabled: Boolean;
  @Prop({ type: Object, default: null}) rules: Object;
  @Prop({ type: Number, default: -1 }) selectedMunicipioId: Number;

  pagerequestMunicipio: PageMunicipioRequest = new PageMunicipioRequest();
  maxResultCountProvincia= 10;
  maxResultCountMunicipio = 10;
  skipCountProvincia = 0;
  skipCountMunicipio = 0;
  loading: boolean = false;
  get municipioList() {
    return this.$store.state.municipio.list;
  }
  get municipioCount() {
      
    return this.$store.state.municipio.totalCount;
  }

  created() {
  }

  async shown() {
    //await this.getEstadoDropDown()
  }

  async nextPageMunicipio(offset: number) {
    this.maxResultCountMunicipio = offset;
    await this.$store.dispatch({
      type: "provincia/getMunicipio",
      data: this.pagerequestMunicipio,
    });
  }

  // @Watch("municipioCount")
  // selectProvinciaChange() {
  //   if (this.value) {
  //     this.value.id = 0;
  //     this.value.nombre = "Municipio";
  //   }
  // }

  municipioRule = {
    municipio: {
      // required: { required: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Municipio")),
      placeholder: this.L("Municipio"),
      trigger: "blur",
    },
  };
}
