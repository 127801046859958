
import AbpBase from '@/lib/abpbase'
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Provincia from '@/store/entities/OZONE/provincia'
import PageRequest from '@/store/entities/page-request'
class PageProvinciaRequest extends PageRequest {
  nombre: string
  id:number
}
class PageMunicipio extends PageRequest {
  Provinciaid: number;
}
@Component
export default class SelectProvincia extends AbpBase {
  @Prop({ type: Object, default: null }) value: Provincia
  @Prop({ type: Boolean, default: true }) showPagination: Boolean
  @Prop({ type: Boolean, default: false }) disabled: Boolean
  @Prop({ type: Object, default: null}) rules: Object
  @Prop({ type: String, default: 'Provincia'}) name: String
  @Prop({ type: String, default: 'Provincia'}) label: String
  provincia: Provincia = null
  pagerequestProvincia: PageProvinciaRequest = new PageProvinciaRequest()
  pagerequestMunicipio: PageMunicipio = new PageMunicipio();
  maxResultCountProvincia = 10
  maxResultCountMunicipio = 10
  skipCountProvincia = 0
  skipCountMunicipio = 0

  get provinciaList() {
    return this.$store.state.provincia.list
  }
  get provinciaCount() {
    return this.$store.state.provincia.totalCount
  }

  async created() {
    this.getProvinciasDropDown()
  }

  async getProvincias(params: any) {
    this.pagerequestProvincia.nombre = params
    this.maxResultCountProvincia = 50
    this.skipCountProvincia = 0
    this.pagerequestProvincia.maxResultCount = this.maxResultCountProvincia
    this.pagerequestProvincia.skipCount = this.skipCountProvincia
    await this.$store.dispatch({
      type: 'provincia/getAll',
      data: this.pagerequestProvincia
    })
  }

  async nextPage(offset: number) {
    this.pagerequestProvincia.skipCount = offset
    await this.$store.dispatch({
      type: 'provincia/getAll',
      data: this.pagerequestProvincia
    })
  }

  async getProvinciasDropDown() {
    this.pagerequestProvincia.maxResultCount = 100;
    this.pagerequestProvincia.skipCount = 0;
    await this.$store.dispatch({
      type: 'provincia/getAll',
      data: this.pagerequestProvincia
    })
  }

  provinciaRule = {
    provincia: {
      message: this.L('ThisFieldIsRequired', undefined, this.L('Provincia')),
      placeholder: this.L('Provincia'),
      trigger: 'blur'
    }
  }
}
